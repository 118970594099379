"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
//@ts-ignore
const solitaire_1 = require("@games/solitaire/src/js-solitaire/solitaire");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class SolitaireCtrl {
    constructor($scope, $timeout, $q, ModalServiceFactory, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$q = $q;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.gameControl = (0, solitaire_1.solitairInitial)(JSON.parse(localStorage.getItem(`${this.constructor.name}_game`) || 'null'), this.gameFinishCallback.bind(this));
        this._startTimer = new rxjs_1.Subject();
        this._stopTimer = new rxjs_1.Subject();
        this.startWith = parseInt(localStorage.getItem(`${this.constructor.name}_startWith`) || '0');
        this.timeRemaining = SolitaireCtrl._formatTime(this.startWith);
        this.visible$ = (0, rxjs_1.fromEvent)(document, 'visibilitychange').pipe((0, operators_1.startWith)('visible'), (0, operators_1.map)(() => {
            if (document.visibilityState != 'visible') {
                this.pauseGame();
            }
            return document.visibilityState;
        }));
        this._resume = new rxjs_1.BehaviorSubject('visible');
        this.timerWork = false;
        this.gameFinish = false;
    }
    $onInit() {
        this.gameControl.initSolitaire();
    }
    gameFinishCallback() {
        console.log('gameFinish', this.gameControl.state.deal.deal.cards, this.gameControl.state.deal.pile.cards);
        const state = {
            cards: this.gameControl.state.cards.map((item) => ({
                facingUp: item.facingUp,
                number: item.number,
                type: item.type
            })),
            deal: {
                deal: { cards: [...this.gameControl.state.deal.deal.cards] },
                pile: { cards: [...this.gameControl.state.deal.pile.cards] },
            },
            desk: this.gameControl.state.desk.map((item) => ({ cards: item.cards })),
            finish: this.gameControl.state.finish.map((item) => ({ cards: item.cards })),
        };
        localStorage.setItem(`${this.constructor.name}_game`, JSON.stringify(state));
    }
    newGame() {
        this._confirmNewGame((result) => {
            if (result) {
                // this.level = level || this.level
                // this.version = version || this.version
                // this.setParam('version', this.version)
                // this.setParam('level', this.level)
                localStorage.removeItem(`${this.constructor.name}_game`);
                localStorage.removeItem(`${this.constructor.name}_startWith`);
                // this.bestScore = JSON.parse(localStorage.getItem(`${this.constructor.name}_bestScores`) || '{}')[`${this.level}:${this.version}`]
                // this.score = 0
                this._startTimer.next(null);
                this.startWith = 0;
                this.timeRemaining = '00:00';
                this.gameFinish = false;
                this.$timeout(() => {
                    // this.game?.destroy()
                    // this.game = new Game(this.level, this.version, this.frameCallback.bind(this))
                    this.gameControl.resetGame();
                });
            }
        });
    }
    pauseGame() {
        if (!this.gameFinish && this.ConfigService.cookieSettings.show_timer) {
            // this.doPause(true)
            this._resume.next('hidden');
            this.ModalServiceFactory.open({
                id: 'paused',
                component: "pause-comp",
                scope: this.$scope,
                strategy: "if_close_all"
            }).then(() => {
                // this.doPause(false)
                this._resume.next('visible');
            });
        }
    }
    _confirmNewGame(callback) {
        if (this.gameFinish || !JSON.parse(localStorage.getItem(`${this.constructor.name}_game`) || '{}').tail) {
            return this.$q.when().then(callback ? callback(true) : null);
        }
        else {
            // const paused = this.game?.paused || !this.game?.moving
            // this.doPause(true)
            this._stopTimer.next(true);
            return this.ModalServiceFactory.open({
                id: 'nonogram_new_game',
                component: "confirm-comp",
                scope: this.$scope,
                extraContext: {
                    settings: {}
                }
            }).then((result) => {
                if (result) {
                    callback ? callback(result) : null;
                }
                else {
                    // if (!paused) {
                    //     this.doPause(false)
                    //     this._startTimer.next(null);
                    //     this._startTimer.next(true);
                    // }
                    throw { error: 'cancel' };
                }
            });
        }
    }
    static _formatTime(sec) {
        if (sec >= 3600) {
            return new Date(sec * 1000).toISOString().substr(11, 8);
        }
        return new Date(sec * 1000).toISOString().substr(14, 5);
    }
}
SolitaireCtrl.$inject = ['$scope', '$timeout', '$q', 'ModalServiceFactory', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gameSolitaire', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: SolitaireCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('solitaire/');
    }]);
